.t {
  transform: none;
}
[search] {
  background: #fff;
}
[search] .head {
  padding-top: 1.6875rem;
  padding-right: 1.125rem;
  padding-bottom: 1.75rem;
  padding-left: 1.125rem;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #f4f4f4;
  position: sticky;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  background-color: #fff;
  z-index: 2;
}
[search] .head .inner {
  max-width: 167.5rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
[search] .head .search {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/img/common/search-ic-search.png');
}
[search] .head .close {
  float: right;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/img/common/search-ic-close.png');
  background-color: transparent;
  vertical-align: middle;
  opacity: 1;
  transform: rotate(0);
  transition: opacity 0.2s ease, transform 0.3s ease;
}
.no-touch [search] .head .close:hover {
  opacity: 0.6;
  transform: rotate(180deg);
}
[search] .head input {
  margin-left: 1rem;
  font-size: 1.375rem;
  line-height: 0.0825rem;
  line-height: 1.32;
  font-weight: 700;
  width: 75%;
}
[search] .head input::placeholder {
  color: #a9a9a9;
}
[search] section {
  max-width: 167.5rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
[search] .init {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  padding-top: 2rem;
  position: relative;
}
[search] .init label {
  font-size: 0.875rem;
  line-height: 0.098125rem;
  line-height: 1.57;
  color: #a9a9a9;
}
[search] .init ul {
  padding-top: 0.625rem;
}
[search] .init ul li a {
  padding-top: 0.125rem;
  padding-right: 0rem;
  padding-bottom: 0.125rem;
  padding-left: 0rem;
  font-size: 1rem;
  line-height: 0.113125rem;
  letter-spacing: -0.01875rem;
  line-height: 1.81;
  color: #333;
}
.no-touch [search] .init ul li a:hover {
  color: #888;
}
[search].open .init ul li {
  opacity: 1;
  transform: translateY(0rem);
}
[search] .no-list {
  text-align: center;
  padding-top: 6.75rem;
  font-size: 1rem;
  font-weight: 500;
}
[search] .result {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  padding-top: 3rem;
  position: relative;
  background-color: #fff;
  min-height: calc(100vh - 85px);
}
[search] .result .tab {
  margin-bottom: 2rem;
}
[search] .result .tab ul li {
  font-weight: 500;
  display: inline-block;
  margin-right: 1rem;
  cursor: pointer;
  color: #a9a9a9;
  transition: color 0.4s ease;
}
[search] .result .tab ul li p {
  font-size: 0.875rem;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
[search] .result .tab ul li p:after {
  content: '';
  width: 0rem;
  height: 0.0875rem;
  position: absolute;
  left: 50%;
  bottom: -0.125rem;
  right: auto;
  top: auto;
  transform: translateX(-50%);
  background-color: #1e1e1e;
  transition: width 0.6s cubic-bezier(0.4, -0.03, 0, 1.03);
}
[search] .result .tab ul li span {
  display: inline-block;
  vertical-align: top;
  font-size: 0.5625rem;
  margin-left: 0.25rem;
}
[search] .result .tab ul li.on {
  color: #1e1e1e;
}
[search] .result .tab ul li.on p:after {
  width: 100%;
}
[search] .result .contents .play-result {
  display: grid;
  grid-template-columns: 1fr;
}
[search] .result .contents .play-result > li {
  position: relative;
  overflow: hidden;
  aspect-ratio: 0.6;
  margin-bottom: 1.25rem;
  color: #fff;
}
[search] .result .contents .play-result > li [cdn-img] {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  opacity: 1;
  transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04), opacity 0.6s ease;
}
[search] .result .contents .play-result > li .over-video {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04);
}
[search] .result .contents .play-result > li .text {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  left: 0rem;
  bottom: 0rem;
  right: auto;
  top: auto;
  padding-top: 2.25rem;
  padding-right: 1.5rem;
  padding-bottom: 2.25rem;
  padding-left: 1.5rem;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}
[search] .result .contents .play-result > li .text .tag {
  font-size: 0.75rem;
  padding-bottom: 0.75rem;
}
[search] .result .contents .play-result > li .text .tag span {
  font-weight: 500;
  display: inline-block;
  margin-left: 0.5rem;
}
[search] .result .contents .play-result > li .text .title {
  font-size: 1.125rem;
  line-height: 0.100625rem;
  letter-spacing: -0.015625rem;
  line-height: 1.61;
  font-weight: 700;
}
[search] .result .contents .play-result > li .sns-wrap {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 4.375rem;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}
[search] .result .contents .play-result > li .sns-wrap [sns-share] {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.75rem;
  margin-right: 0.875rem;
  margin-bottom: 0.75rem;
  margin-left: 0.875rem;
  position: absolute;
  right: 0rem;
  top: 0rem;
  left: auto;
  bottom: auto;
}
[search] .result .contents .play-result > li .sns-wrap [sns-share] a.btn {
  background-image: url('/img/common/ic-share-more.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.no-touch [search] .result .contents .play-result > li:hover [cdn-img],
.no-touch [search] .result .contents .play-result > li:hover video {
  transform: scale(1.1);
}
.no-touch [search] .result .contents .play-result > li:hover.has-video [cdn-img] {
  opacity: 0;
}
[search] .result .contents .news-result {
  display: grid;
  grid-template-columns: 1fr;
}
[search] .result .contents .news-result li {
  position: relative;
  margin-bottom: 3rem;
}
[search] .result .contents .news-result li a {
  display: block;
}
[search] .result .contents .news-result li .img-wrap {
  margin-bottom: 1.25rem;
  aspect-ratio: 1.88888889;
  overflow: hidden;
  position: relative;
}
[search] .result .contents .news-result li .img-wrap img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04);
}
[search] .result .contents .news-result li .text {
  color: #333;
}
[search] .result .contents .news-result li .text .tag {
  font-size: 0.75rem;
  padding-bottom: 0.625rem;
}
[search] .result .contents .news-result li .text .tag span {
  font-weight: 500;
  display: inline-block;
  margin-left: 0.75rem;
}
[search] .result .contents .news-result li .text .title {
  font-size: 1.125rem;
  line-height: 0.100625rem;
  letter-spacing: -0.015625rem;
  line-height: 1.61;
  font-weight: 700;
}
.no-touch [search] .result .contents .news-result li:hover .img-wrap img {
  transform: scale(1.1);
}
.dark [search] {
  background: #000;
}
.dark [search] .head {
  background-color: #000;
  border-bottom: 1px solid #222;
}
.dark [search] .head .search {
  background-image: url('/img/common/search-ic-search-dark.png');
}
.dark [search] .head .close {
  background-image: url('/img/common/search-ic-close-dark.png');
}
.dark [search] .head input {
  color: #fff;
}
.dark [search] .head input::placeholder {
  color: #888;
}
.dark [search] .init label {
  color: #888;
}
.dark [search] .init ul li a {
  color: #ebebeb;
}
.dark [search] .result {
  background-color: #000;
}
.dark [search] .result .tab ul li {
  color: #666;
}
.dark [search] .result .tab ul li p:after {
  background-color: #fff;
}
.dark [search] .result .tab ul li.on {
  color: #fff;
}
.dark [search] .contents .news-result li .text {
  color: #fff;
}
@media (min-width: 768px) {
  [search] .head {
    padding-top: 1.6875rem;
    padding-right: 2.25rem;
    padding-bottom: 1.75rem;
    padding-left: 2.25rem;
  }
  [search] .head input {
    margin-left: 1.5rem;
  }
  [search] .init {
    padding-top: 2.0625rem;
    padding-right: 2.25rem;
    padding-bottom: 2.0625rem;
    padding-left: 2.25rem;
  }
  [search] .result {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    padding-top: 3.75rem;
  }
  [search] .result .tab {
    margin-bottom: 1.5rem;
  }
  [search] .result .contents .play-result {
    grid-template-columns: 1fr 1fr;
    column-gap: 18px;
  }
  [search] .result .contents .play-result > li {
    margin-bottom: 1.125rem;
  }
  [search] .result .contents .play-result > li .sns-wrap [sns-share] {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0.75rem;
  }
  [search] .result .contents .news-result {
    grid-template-columns: 1fr 1fr;
    column-gap: 18px;
  }
  [search] .result .contents .news-result li {
    margin-bottom: 3.5rem;
  }
}
@media (min-width: 1024px) {
  [search] .head {
    padding-top: 1.9375rem;
    padding-right: 2.25rem;
    padding-bottom: 2rem;
    padding-left: 2.25rem;
  }
  [search] .head .search {
    width: 1.5625rem;
    height: 1.5625rem;
  }
  [search] .head .close {
    width: 1.5625rem;
    height: 1.5625rem;
  }
  [search] .head input {
    font-size: 1.625rem;
  }
  [search] .init {
    padding-top: 2rem;
    padding-right: 2.25rem;
    padding-bottom: 2rem;
    padding-left: 2.25rem;
  }
  [search] .init ul {
    padding-top: 0.5rem;
  }
  [search] .init ul li a {
    padding-top: 0.25rem;
    padding-right: 0rem;
    padding-bottom: 0.25rem;
    padding-left: 0rem;
  }
  [search] .result {
    padding-top: 3.75rem;
    min-height: calc(100vh - 99px);
  }
  [search] .result .tab {
    margin-bottom: 1.5rem;
  }
  [search] .result .contents .play-result {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 14px;
  }
  [search] .result .contents .play-result > li {
    margin-bottom: 0.875rem;
  }
  [search] .result .contents .play-result > li .text {
    padding-top: 1.75rem;
    padding-right: 1.375rem;
    padding-bottom: 1.75rem;
    padding-left: 1.375rem;
  }
  [search] .result .contents .play-result > li .sns-wrap [sns-share] {
    margin-top: 0.75rem;
    margin-right: 0.875rem;
    margin-bottom: 0.75rem;
    margin-left: 0.875rem;
  }
  [search] .result .contents .news-result {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 14px;
  }
  [search] .result .contents .news-result li {
    margin-bottom: 4.5rem;
  }
}
@media (min-width: 1440px) {
  [search] .head {
    padding-top: 2.6875rem;
    padding-right: 3rem;
    padding-bottom: 2.75rem;
    padding-left: 3rem;
  }
  [search] .head .search {
    width: 2.25rem;
    height: 2.25rem;
  }
  [search] .head .close {
    width: 2.25rem;
    height: 2.25rem;
  }
  [search] .head input {
    margin-left: 2.25rem;
    font-size: 2.25rem;
    width: 80%;
  }
  [search] .no-list {
    padding-top: 10rem;
    font-size: 1.5rem;
  }
  [search] .init {
    padding-top: 2rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
    padding-left: 3rem;
  }
  [search] .result {
    padding-top: 6.25rem;
    min-height: calc(100vh - 135px);
  }
  [search] .result .tab {
    margin-bottom: 2rem;
  }
  [search] .result .tab ul li {
    margin-right: 1.5rem;
  }
  [search] .result .tab ul li p {
    font-size: 1rem;
  }
  [search] .result .tab ul li span {
    font-size: 0.6875rem;
    margin-left: 0.3125rem;
  }
  [search] .result .contents .play-result {
    column-gap: 24px;
  }
  [search] .result .contents .play-result > li {
    margin-bottom: 1.5rem;
  }
  [search] .result .contents .play-result > li .text {
    padding-top: 2.25rem;
    padding-right: 1.875rem;
    padding-bottom: 2.25rem;
    padding-left: 1.875rem;
  }
  [search] .result .contents .play-result > li .text .tag span {
    margin-left: 0.75rem;
  }
  [search] .result .contents .play-result > li .text .title {
    font-size: 1.375rem;
    line-height: 0.099375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.59;
  }
  [search] .result .contents .play-result > li .sns-wrap {
    height: 6.875rem;
  }
  [search] .result .contents .play-result > li .sns-wrap [sns-share] {
    width: 1.5rem;
    height: 1.5rem;
    margin: 1rem;
  }
  [search] .result .contents .news-result {
    column-gap: 24px;
  }
  [search] .result .contents .news-result li {
    margin-bottom: 5.25rem;
  }
}
@media (min-width: 1600px) {
  [search] .head {
    padding-top: 3.625rem;
    padding-right: 3.75rem;
    padding-bottom: 3.625rem;
    padding-left: 3.75rem;
  }
  [search] .head .search {
    width: 2.75rem;
    height: 2.75rem;
  }
  [search] .head .close {
    width: 2.75rem;
    height: 2.75rem;
  }
  [search] .head input {
    margin-left: 3rem;
    font-size: 3rem;
  }
  [search] .init {
    padding-top: 2.75rem;
    padding-right: 3.75rem;
    padding-bottom: 2.75rem;
    padding-left: 3.75rem;
  }
  [search] .init label {
    font-size: 1.125rem;
  }
  [search] .init ul {
    padding-top: 0.75rem;
  }
  [search] .init ul li a {
    padding-top: 0.5rem;
    padding-right: 0rem;
    padding-bottom: 0.5rem;
    padding-left: 0rem;
    font-size: 1.375rem;
  }
  [search] .result {
    padding-top: 7.5rem;
    min-height: calc(100vh - 180px);
  }
  [search] .result .tab {
    margin-bottom: 3.0625rem;
  }
  [search] .result .tab ul li {
    margin-right: 2rem;
  }
  [search] .result .tab ul li p {
    font-size: 1.375rem;
  }
  [search] .result .tab ul li span {
    font-size: 0.9375rem;
  }
  [search] .result .contents .play-result {
    column-gap: 33px;
  }
  [search] .result .contents .play-result > li {
    margin-bottom: 2.0625rem;
  }
  [search] .result .contents .play-result > li .text {
    padding-top: 3rem;
    padding-right: 2.5rem;
    padding-bottom: 3rem;
    padding-left: 2.5rem;
  }
  [search] .result .contents .play-result > li .text .tag {
    font-size: 1rem;
    padding-bottom: 1rem;
  }
  [search] .result .contents .play-result > li .text .tag span {
    margin-left: 1rem;
  }
  [search] .result .contents .play-result > li .text .title {
    font-size: 1.75rem;
    line-height: 0.098125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.57;
  }
  [search] .result .contents .play-result > li .sns-wrap [sns-share] {
    width: 2rem;
    height: 2rem;
    margin-top: 1.1875rem;
    margin-right: 1.3125rem;
    margin-bottom: 1.1875rem;
    margin-left: 1.3125rem;
  }
  [search] .result .contents .news-result {
    column-gap: 24px;
  }
  [search] .result .contents .news-result li {
    margin-bottom: 6.75rem;
  }
  [search] .result .contents .news-result li .img-wrap {
    margin-bottom: 1.625rem;
  }
  [search] .result .contents .news-result li .text .tag {
    font-size: 1rem;
    padding-bottom: 0.8125rem;
  }
  [search] .result .contents .news-result li .text .tag span {
    margin-left: 1rem;
  }
  [search] .result .contents .news-result li .text .title {
    font-size: 1.5rem;
    line-height: 0.09875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.58;
  }
}
